import { QueryClient } from '@tanstack/react-query'

export const queryClient = new QueryClient()

//setup some data used everywhere
queryClient.setQueryData(['quarters'], () => {
  return [
    { label: 1, value: 1 },
    { label: 2, value: 2 },
    { label: 3, value: 3 },
    { label: 4, value: 4 }
  ]
})

const currentYear = new Date().getFullYear()

queryClient.setQueryData(['years'], () => {
  return [
    { label: currentYear - 4, value: currentYear - 4 },
    { label: currentYear - 3, value: currentYear - 3 },
    { label: currentYear - 2, value: currentYear - 2 },
    { label: currentYear - 1, value: currentYear - 1 },
    { label: currentYear, value: currentYear },
    { label: currentYear + 1, value: currentYear + 1 }
  ]
})

queryClient.setDefaultOptions({
  queries: {
    refetchOnWindowFocus: false
  }
})

// default to the prior quarter (and possibly the prior year) because taxes are filed in the quarter following the respective quarter
// example: it is January 2022, the tax team is filing and paying taxes for Q4 2021
export const getDefaultYear = (date: Date) => {
  const currentYear = date.getFullYear()
  const currentMonth = date.getMonth() + 1

  if (currentMonth === 1 || currentMonth === 2 || currentMonth === 3) {
    return currentYear - 1
  } else {
    return currentYear
  }
}

export const getDefaultQuarter = (date: Date) => {
  const currentMonth = date.getMonth() + 1

  if (currentMonth === 1 || currentMonth === 2 || currentMonth === 3) {
    return 4
  } else if (currentMonth === 4 || currentMonth === 5 || currentMonth === 6) {
    return 1
  } else if (currentMonth === 7 || currentMonth === 8 || currentMonth === 9) {
    return 2
  } else {
    return 3
  }
}

export const formatDate = (date: Date): string => {
  const dateString = date.toISOString()
  const [yearMonthDay] = dateString.split('T')

  return yearMonthDay
}
