import * as React from 'react'
import { useEffect, useState } from 'react'
import {
  Body,
  Cell,
  Head,
  HeadingCell,
  Row,
  Table
} from '@toasttab/buffet-pui-table'
import { Checkbox } from '@toasttab/buffet-pui-checkbox'
import { Row as TableRow, useRowSelect, useTable } from 'react-table'
import {
  PagedList,
  VoidFilterType,
  VoidRequest,
  VoidRequestStatus
} from './models/voidRequestModel'
import { Pagination, useRowPagination } from '@toasttab/buffet-pui-pagination'
import { useQuery } from '@tanstack/react-query'
import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'
import { ListItem, MenuDropdown } from '@toasttab/buffet-pui-dropdowns'
import { Button, IconButton } from '@toasttab/buffet-pui-buttons'
import { DeleteIcon, DoneIcon, MoreVertIcon } from '@toasttab/buffet-pui-icons'
import { VoidRequestDeleteConfirmationModal } from './VoidRequestDeleteConfirmationModal'
import { VoidRequestApprovalModal } from './VoidRequestApprovalModal'
import { VoidRequestFilterSelection } from './VoidRequestFilterSelection'
import { useSearchParams } from 'react-router-dom'
import { CardContainer } from '@toasttab/buffet-pui-card'

export default {
  title: 'components/Data/Table/Table',
  component: Table,
  subcomponents: { Head, Body, HeadingCell, Cell }
}

const PAGE_SIZE = 20
const EMPTY_LIST: readonly VoidRequest[] = []
const CLASSNAME_BY_STATUS = {
  [VoidRequestStatus.OPEN]: 'md:grid-cols-5',
  [VoidRequestStatus.CLOSED]: 'md:grid-cols-4',
  [VoidRequestStatus.EXPIRED]: 'md:grid-cols-3'
}
const COLUMNS = [
  'Customer',
  'Original Pay Period',
  'Target Pay Period',
  'Employee Name',
  'Request Date',
  'Requested User',
  'Net Pay Notes',
  'Pay Stub Number',
  'Notes',
  'Request Type',
  'Status User'
].map((header) => {
  let accessor =
    header === 'Original Pay Period'
      ? 'sourcePayPeriod'
      : (header.charAt(0).toLowerCase() + header.slice(1)).replaceAll(' ', '')
  return {
    Header: header,
    accessor,
    id: accessor
  }
})

export function VoidRequestTable({
  status,
  selected
}: {
  status: VoidRequestStatus
  selected: boolean
}) {
  const [page, setPage] = useState<number>(1)
  const [searchParams] = useSearchParams()
  const [selectedSingleId, setSelectedSingleId] = useState<number | null>(null)
  const [company, setCompany] = useState<string | undefined>(undefined)
  const [payPeriod, setPayPeriod] = useState<string | undefined>(undefined)
  const [quarter, setQuarter] = useState<string | undefined>(undefined)
  const [selectedPayPeriod, selectPayPeriod] = useState<{
    customer: string
    payPeriod: string | null
  } | null>(null)
  const [isDeletionModalOpen, setDeletionModalOpen] = useState<boolean>(false)
  const [isApprovalModalOpen, setApprovalModalOpen] = useState<boolean>(false)
  const [checked, toggleChecked] = useState(false)
  const [selectedVoidIds, setSelectedVoidIds] = React.useState<Set<number>>(
    new Set<number>()
  )

  const pagingParams =
    status === VoidRequestStatus.OPEN
      ? ''
      : '&perPage=' + PAGE_SIZE + '&page=' + page

  const showAll = searchParams.has('showAll')
    ? searchParams.get('showAll') === 'true'
    : false

  const companyFilter = company ? `&customer=${company}` : ''
  const payPeriodFilter = company && payPeriod ? `&payPeriod=${payPeriod}` : ''
  const yearQuarter = quarter?.split(',')
  const quarterFilter =
    yearQuarter?.length === 2
      ? `&year=${yearQuarter[0]}&quarter=${yearQuarter[1]}`
      : ''

  const showAllFilter = `&showAll=${showAll}`

  const fetchVoidRequests = async () => {
    const res = await fetch(
      `/void-requests/?status=${status}${pagingParams}${companyFilter}${payPeriodFilter}${quarterFilter}${showAllFilter}`
    )
    if (!res.ok) {
      throw new Error('HTTP error: ' + res.status)
    }
    return res.json()
  }

  const resetState = (reload: boolean) => {
    if (!reload) {
      return
    }

    if (selectedSingleId) {
      const newIds = new Set(selectedVoidIds)
      newIds.delete(selectedSingleId)
      setSelectedSingleId(null)
      setSelectedVoidIds(newIds)
    } else {
      setSelectedVoidIds(new Set())
    }
    refetch()
  }

  const deleteSingleVoidRequest = (id: number) => {
    setDeletionModalOpen(true)
    setSelectedSingleId(id)
  }

  const approveSingleVoidRequest = (request: VoidRequest) => {
    setApprovalModalOpen(true)
    setSelectedSingleId(request.id)
    selectPayPeriod({
      payPeriod: request.sourcePayPeriodUuid,
      customer: request.customerUuid
    })
  }

  const {
    data,
    isFetching,
    refetch
  }: {
    data: undefined | null | PagedList<VoidRequest>
    isFetching: boolean
    refetch: any
  } = useQuery({
    queryKey: [page, status, selected, company, payPeriod, quarter],
    queryFn: () => {
      if (selected) {
        return fetchVoidRequests()
      } else {
        return Promise.resolve({
          totalCount: 0,
          results: []
        })
      }
    }
  })

  useEffect(() => {
    if (status === VoidRequestStatus.CLOSED && data) {
      toggleChecked(
        data.results.every(
          (request: VoidRequest) =>
            !request.isScheduled || selectedVoidIds.has(request.id)
        )
      )
    } else if (
      status === VoidRequestStatus.OPEN &&
      company &&
      payPeriod &&
      data
    ) {
      toggleChecked(
        data.results.every((request: VoidRequest) =>
          selectedVoidIds.has(request.id)
        )
      )
    }
  }, [data, data?.results, page, selectedVoidIds, status])

  const checkAll = () => {
    const newIds = new Set(selectedVoidIds)
    data?.results
      .filter((request: VoidRequest) => request.isScheduled)
      .forEach((request: VoidRequest) => {
        !checked ? newIds.add(request.id) : newIds.delete(request.id)
      })
    setSelectedVoidIds(newIds)
    toggleChecked(!checked)
  }

  const selectRequest = (id: number) => {
    const newIds = new Set(selectedVoidIds)
    selectedVoidIds.has(id) ? newIds.delete(id) : newIds.add(id)
    setSelectedVoidIds(newIds)
  }

  const columns: any = React.useMemo(
    () => [
      {
        Header: () =>
          (status === VoidRequestStatus.OPEN && payPeriod) ||
          (status === VoidRequestStatus.CLOSED &&
            data?.results.some(
              (request: VoidRequest) => request.isScheduled
            )) ? (
            <Checkbox onChange={() => checkAll()} checked={checked} label='' />
          ) : null,
        id: 'selectAll',
        className: 'relative text-right',
        Cell: ({ row }: { row: TableRow<VoidRequest> }) =>
          status === VoidRequestStatus.OPEN ||
          (status === VoidRequestStatus.CLOSED && row.original.isScheduled) ? (
            <Checkbox
              onChange={() => selectRequest(row.original.id)}
              checked={selectedVoidIds.has(row.original.id)}
              label=''
            />
          ) : null
      },
      ...COLUMNS,
      {
        Header: () => null,
        id: 'actions',
        className: 'relative text-right',
        Cell: ({ row }: { row: TableRow<VoidRequest> }) => (
          <div>
            <MenuDropdown
              placement='bottom-end'
              renderToggle={(props: any) => (
                <IconButton
                  icon={<MoreVertIcon aria-label='Options' />}
                  {...props}
                />
              )}
            >
              <ListItem
                label='Approve'
                onClick={() => approveSingleVoidRequest(row.original)}
              />
              <ListItem
                label='Delete'
                onClick={() => deleteSingleVoidRequest(row.original.id)}
              />
            </MenuDropdown>
          </div>
        )
      }
    ],
    [checked, data, selectedVoidIds]
  )

  const { headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data: data ? data.results : EMPTY_LIST,
      getRowId: (row: VoidRequest) => row.id.toString(),
      initialState: {
        hiddenColumns:
          status === VoidRequestStatus.OPEN
            ? ['targetPayPeriod', 'statusUser', 'requestType']
            : ['requestType', 'payStubNumber', 'actions']
      }
    },
    useRowSelect
  )

  const { currentPageData, ...paginationProps } = useRowPagination({
    rows: () => rows,
    currentPage: page - 1,
    pageSize:
      status === VoidRequestStatus.OPEN && data ? data.totalCount : PAGE_SIZE,
    totalRows: data?.totalCount,
    onChangePage: (newPage: number) => setPage(newPage + 1)
  })

  return (
    <div className='inline-block space-y-4 w-full'>
      {selected && (
        <div className={`grid ${CLASSNAME_BY_STATUS[status]} gap-4 items-end`}>
          <VoidRequestFilterSelection
            filterType={VoidFilterType.CUSTOMER}
            currentValue={company}
            showAll={showAll}
            status={status}
            disabled={!data?.totalCount && !company && !quarter}
            setFilterValue={(value: string) => {
              setPayPeriod(undefined)
              setCompany(value)
              setSelectedVoidIds(new Set())
            }}
          />
          <VoidRequestFilterSelection
            filterType={VoidFilterType.QUARTER}
            currentValue={quarter}
            showAll={showAll}
            disabled={!data?.totalCount && !company && !quarter}
            status={status}
            setFilterValue={(value: string) => {
              setPayPeriod(undefined)
              setQuarter(value)
              setSelectedVoidIds(new Set())
            }}
          />
          <VoidRequestFilterSelection
            filterType={VoidFilterType.PAYPERIOD}
            currentValue={payPeriod}
            parentValue={company}
            showAll={showAll}
            disabled={!company}
            status={status}
            setFilterValue={(value: string) => {
              setQuarter(undefined)
              setPayPeriod(value)
              setSelectedVoidIds(new Set())
            }}
          />
          {status !== VoidRequestStatus.EXPIRED && (
            <Button
              iconLeft={<DeleteIcon accessibility='decorative' />}
              variant='secondary'
              onClick={() => {
                setDeletionModalOpen(true)
              }}
              disabled={selectedVoidIds.size === 0}
            >
              Delete all
            </Button>
          )}
          {status === VoidRequestStatus.OPEN && (
            <Button
              iconLeft={<DoneIcon accessibility='decorative' />}
              onClick={() => {
                if (company && payPeriod) {
                  selectPayPeriod({
                    payPeriod: payPeriod,
                    customer: company
                  })
                }
                setApprovalModalOpen(true)
              }}
              disabled={!payPeriod || !company || selectedVoidIds.size === 0}
            >
              Approve all
            </Button>
          )}
        </div>
      )}
      <CardContainer>
        {isFetching && <MerryGoRound />}
        {!isFetching && data?.totalCount === 0 && (
          <div className='type-default'>
            There are no {status.toLowerCase()} void requests
          </div>
        )}

        {!isFetching && data && data.totalCount > 0 && (
          <div className='overflow-x-scroll'>
            <div className='type-default text-secondary mb-10'>
              Following are void requests submitted by users. You can approve
              open requests. Requests that are already approved or denied are
              for reporting purposes only.
            </div>
            <Table variant='striped' density='condensed' valign='middle'>
              <Head>
                {headerGroups.map((headerGroup) => (
                  <Row
                    {...headerGroup.getHeaderGroupProps()}
                    key={`headingrow-${headerGroup.id}`}
                  >
                    {headerGroup.headers.map((column) => (
                      <HeadingCell
                        {...column.getHeaderProps({
                          className: column.className
                        })}
                        key={`row-${headerGroup.id}-col-${column.id}`}
                      >
                        {column.render('Header')}
                      </HeadingCell>
                    ))}
                  </Row>
                ))}
              </Head>
              <Body>
                {rows.map((row) => {
                  prepareRow(row)
                  return (
                    <Row {...row.getRowProps()} key={`row-${row.original.id}`}>
                      {row.cells.map((cell) => {
                        return (
                          <Cell
                            {...cell.getCellProps({
                              className: `${cell.column.className} type-subhead`,
                              style: {
                                maxWidth:
                                  cell.column.id === 'payStubNumber'
                                    ? '10px'
                                    : '110px',
                                overflowWrap:
                                  cell.column.id === 'employeeName'
                                    ? 'normal'
                                    : 'break-word'
                              }
                            })}
                            key={`row-${row.original.id}-cell-${cell.column.id}`}
                          >
                            {cell.column.id === 'requestDate'
                              ? new Date(cell.value).toLocaleString()
                              : cell.render('Cell')}
                          </Cell>
                        )
                      })}
                    </Row>
                  )
                })}
              </Body>
            </Table>
            <VoidRequestDeleteConfirmationModal
              ids={[...selectedVoidIds]}
              selectedSingleId={selectedSingleId}
              isDeletionModalOpen={isDeletionModalOpen}
              setDeletionModalOpen={(reload: boolean) => {
                setDeletionModalOpen(false)
                resetState(reload)
              }}
            />
            <VoidRequestApprovalModal
              ids={[...selectedVoidIds]}
              selectedSingleId={selectedSingleId}
              selectedPayPeriod={selectedPayPeriod}
              isModalOpen={isApprovalModalOpen}
              setModalOpen={(reload: boolean) => {
                setApprovalModalOpen(false)
                selectPayPeriod(null)
                resetState(reload)
              }}
            />
            {status !== VoidRequestStatus.OPEN && (
              <Pagination
                {...paginationProps}
                className='flex justify-end p-4 border-b-2'
              />
            )}
          </div>
        )}
      </CardContainer>
    </div>
  )
}
