import * as React from 'react'
import { Outlet, RouteObject } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'
import { Button } from '@toasttab/buffet-pui-buttons'
import { Modal } from '@toasttab/buffet-pui-modal'
import {
  DividingLine,
  LayoutProvider,
  Page,
  PageBody,
  Panel,
  SettingGroupHeader
} from '@toasttab/buffet-pui-config-templates'
import { useUser } from '@toasttab/ec-session'
import { useSnackBar } from '@toasttab/buffet-pui-snackbars'

export const RecalculateSutaWagesRoute: RouteObject = {
  path: 'payroll-ops/recalculate-suta-wages',
  element: <RecalculateSutaWages />,
  children: []
}

export const isButtonEnabled = (date: Date, email: string): boolean => {
  const emails = [
    'rachael.hart.smith@toasttab.com',
    'steffen.felt@toasttab.com',
    'chelsea.qi@toasttab.com',
    'matthew.warren@toasttab.com',
    'rich.manoski@toasttab.com',
    'samantha.runyan@toasttab.com'
  ]

  const isAuthorized = emails.includes(email)
  const isFilingMonth = [0, 3, 6, 9].includes(date.getMonth())

  return isAuthorized || isFilingMonth
}

function RecalculateSutaWages() {
  const { isUserSuperAdmin, email } = useUser()

  const [disabled, setIsDisabled] = React.useState(
    !isButtonEnabled(new Date(), email)
  )
  const [isOpen, setIsOpen] = React.useState(false)

  if (!isUserSuperAdmin) {
    return (
      <div className='type-default'>hmm... you aren't suppose to be here</div>
    )
  }

  return (
    <LayoutProvider>
      <Page className='mt-10'>
        <PageBody className='type-default'>
          <Panel className='space-y-3'>
            <div className='flex justify-between items-center'>
              <SettingGroupHeader title='Recalculate SUTA wages' />
              <Button
                onClick={() => {
                  setIsOpen(true)
                  setIsDisabled(true)
                }}
                disabled={disabled}
              >
                Recalculate
              </Button>
            </div>
            <DividingLine />
            <div>
              This process removes subject wages for employees who were dropped
              from SUTA filings due to missing SSNs and recalculates subject
              wages for employees who were previously dropped from SUTA filings
              but have since added their SSN. This is done in tandem with
              pulling RTS files by a quarter end coordinator.
            </div>
            <div>
              Please do not click this button unless expressly authorized. If
              you have questions about this process, please contact your
              manager.
            </div>
          </Panel>
          <Outlet />
        </PageBody>
      </Page>
      <ConfirmationModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        setIsDisabled={setIsDisabled}
      />
    </LayoutProvider>
  )
}

type ConfirmationModalProps = {
  isOpen: boolean
  setIsOpen: (open: boolean) => void
  setIsDisabled: (disabled: boolean) => void
}

const ConfirmationModal = ({
  isOpen,
  setIsOpen,
  setIsDisabled
}: ConfirmationModalProps) => {
  const { showSuccessSnackBar, showErrorSnackBar } = useSnackBar()

  const { mutateAsync: recalculateSutaWages } = useMutation({
    mutationFn: async () => {
      const response = await fetch('/suta-wages/recalculate', {
        method: 'PUT'
      })

      if (!response.ok) {
        throw new Error('HTTP error: ' + response.status)
      }

      return response
    },
    onSuccess: () => {
      showSuccessSnackBar('SUTA wages are being recalculated')
    },
    onError: (error) => {
      showErrorSnackBar('Error recalculating SUTA wages')
      console.error('Error recalculating SUTA wages: ', error)
    }
  })

  return (
    <>
      <Modal isOpen={isOpen}>
        <Modal.Body>
          <div className='space-y-3'>
            <div>
              Are you absolutely 100% positive you actually want to recalculate
              SUTA wages?
            </div>
            <div>This is a very dangerous action and cannot be undone.</div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className='flex-grow sm:flex-none'
            variant='link'
            onClick={() => {
              setIsOpen(false)
              setIsDisabled(false)
            }}
          >
            Cancel
          </Button>
          <Button
            className='flex-grow sm:flex-none'
            onClick={() => {
              recalculateSutaWages()
              setIsOpen(false)
            }}
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default RecalculateSutaWages
