import * as React from 'react'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Button } from '@toasttab/buffet-pui-buttons'
import { useSnackBar } from '@toasttab/buffet-pui-snackbars'
import { Modal, ModalBody, ModalHeader } from '@toasttab/buffet-pui-modal'
import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'
import { useState } from 'react'
import { Select, SelectOption } from '@toasttab/buffet-pui-select'
import { IdentifiedObject } from './models/voidRequestModel'

export function VoidRequestApprovalModal({
  ids,
  selectedSingleId,
  selectedPayPeriod,
  isModalOpen,
  setModalOpen
}: {
  ids: number[]
  selectedSingleId: number | null
  selectedPayPeriod: {
    customer: string
    payPeriod: string | null
  } | null
  setModalOpen: (reload: boolean) => void
  isModalOpen: boolean
}) {
  const { showSuccessSnackBar, showErrorSnackBar } = useSnackBar()
  const [targetPayPeriod, setTargetPayPeriod] = useState<string | null>(null)

  const { mutate: approveRequests, isPending } = useMutation({
    mutationFn: async () => {
      const response = await fetch('/void-requests/approve', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/com.toasttab.payroll.v1+json'
        },
        body: JSON.stringify({
          sourcePayPeriodUuid: selectedPayPeriod?.payPeriod,
          targetPayPeriodUuid: targetPayPeriod,
          customerUuid: selectedPayPeriod?.customer,
          voidRequestsIds: selectedSingleId ? [selectedSingleId] : ids
        })
      })
      if (!response.ok) {
        throw new Error('HTTP error: ' + response.status)
      }
    },
    onSuccess: () => {
      setModalOpen(true)
      showSuccessSnackBar('Void requests have been approved')
    },
    onError: () => {
      setModalOpen(false)
      showErrorSnackBar('Error approving void requests')
    }
  })

  return (
    <>
      <Modal isOpen={isModalOpen}>
        <ModalHeader>Approve Void Request</ModalHeader>
        <React.Suspense
          fallback={
            <ModalBody>
              <MerryGoRound className='mx-auto mt-7' />
            </ModalBody>
          }
        >
          {!isModalOpen || !selectedPayPeriod?.payPeriod ? (
            <div>no pay period found</div>
          ) : isPending ? (
            <div className='flex self-center'>
              <MerryGoRound />
            </div>
          ) : (
            <VoidRequestApprovalModalBody
              payPeriodUuid={selectedPayPeriod!.payPeriod!}
              setTargetPayPeriod={setTargetPayPeriod}
            />
          )}
        </React.Suspense>
        <Modal.Footer>
          <Button
            className='flex-grow sm:flex-none'
            variant='link'
            onClick={() => {
              setModalOpen(false)
            }}
          >
            Cancel
          </Button>
          <Button
            className='flex-grow sm:flex-none'
            onClick={() => {
              approveRequests()
            }}
            disabled={!targetPayPeriod || !selectedPayPeriod || isPending}
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export const VoidRequestApprovalModalBody = ({
  payPeriodUuid,
  setTargetPayPeriod
}: {
  payPeriodUuid: string
  setTargetPayPeriod: (value: string) => void
}) => {
  const [selectedPayPeriod, setSelectedPayPeriod] =
    React.useState<SelectOption | null>(null)
  const fetchTargetPayPeriods = async () => {
    const res = await fetch(
      `/void-requests/${payPeriodUuid}/target-pay-periods`
    )
    if (!res.ok) {
      throw new Error('HTTP error: ' + res.status)
    }
    return res.json()
  }

  const {
    data
  }: {
    data: undefined | null | IdentifiedObject[]
    isFetching: boolean
  } = useQuery({
    queryKey: [payPeriodUuid],
    queryFn: fetchTargetPayPeriods
  })

  return (
    <ModalBody>
      {!data || data.length === 0 ? (
        <div>No future pay periods found</div>
      ) : (
        <Select
          label='Target Payperiod'
          name='Target Payperiod'
          value={selectedPayPeriod}
          options={data.map((d) => ({
            label: d.name,
            value: d.uuid
          }))}
          itemToValue={(item) => item}
          onChange={(value) => {
            if (value?.value) {
              setSelectedPayPeriod(value)
              setTargetPayPeriod(value.value)
            }
          }}
        />
      )}
    </ModalBody>
  )
}
