import * as React from 'react'
import { Outlet, RouteObject } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { queryClient } from '@local/api/client'

import {
  Page,
  PageBody,
  Panel,
  LayoutProvider,
  SettingGroupHeader,
  DividingLine
} from '@toasttab/buffet-pui-config-templates'
import { SelectField } from '@toasttab/buffet-pui-forms'
import { Formik, useFormikContext } from 'formik'
import { Button } from '@toasttab/buffet-pui-buttons'
import { SelectOption } from '@toasttab/buffet-pui-select'

export const TaxFilingExclusionDetailsRoute: RouteObject = {
  path: '/mvc/:client/company/standardreports/taxfilingexclusiondetails',
  element: <TaxFilingExclusionDetails />,
  children: []
}

type CustomerFein = {
  uuid: string
  name: string
  tin: string
}

type ReportForm = {
  customerFeinUuid: string
  year: number | undefined
  quarter: number | undefined
  datetime: string
}

export const formatFeins = (
  feins: CustomerFein[] | undefined,
  isFetching: boolean
): SelectOption[] => {
  if (isFetching) {
    return [{ label: 'Loading customer FEINs..', value: '' }]
  } else if (feins) {
    return feins.map((fein) => {
      return { label: `${fein.name} - ${fein.tin}`, value: fein.uuid }
    })
  } else {
    return [{ label: 'No FEINs found for this customer', value: '' }]
  }
}

// we only started recording the ssn history in 2024 so we are filtering out prior years from the client
export const filterYears = (yearOptions: SelectOption[]): SelectOption[] => {
  return yearOptions.filter((option) => parseInt(option.value!) >= 2024)
}

export const formatDateTime = (dateTime: string): string => {
  const date = new Date(dateTime).toLocaleDateString()
  // eslint-disable-next-line @toasttab/buffet/hard-coded-dates
  const time = new Date(dateTime).toLocaleTimeString()

  return `${date} ${time}`
}

export const formatRtsRuns = (
  rtsRuns: string[],
  values: ReportForm,
  status: string,
  isFetching: boolean
): SelectOption[] => {
  const { customerFeinUuid, quarter, year } = values

  if (isFetching) {
    return [{ label: 'Loading recent RTS runs...', value: '' }]
  }

  if (
    status === 'success' &&
    rtsRuns.length === 0 &&
    customerFeinUuid !== '' &&
    quarter !== undefined &&
    year !== undefined
  ) {
    return [{ label: 'No RTS runs found for this FEIN', value: '' }]
  }

  return rtsRuns.map((rtsRun) => {
    return { label: formatDateTime(rtsRun), value: rtsRun }
  })
}

export const generateHref = (values: any): string => {
  return `/payroll-operations/reports/tax-filing-exclusions/${values.customerFeinUuid}?quarter=${values.quarter}&year=${values.year}&datetime=${values.datetime}`
}

function TaxFilingExclusionDetails() {
  const [disabled, isDisabled] = React.useState(false)
  const yearOptions = queryClient.getQueryData(['years']) as SelectOption[]
  const quarterOptions = queryClient.getQueryData([
    'quarters'
  ]) as SelectOption[]

  return (
    <Formik<ReportForm>
      initialValues={{
        customerFeinUuid: '',
        year: undefined,
        quarter: undefined,
        datetime: ''
      }}
      onSubmit={() => {}}
    >
      {({ values, handleSubmit }) => (
        <LayoutProvider>
          <Page className='mt-10'>
            <PageBody className='type-default'>
              <Panel className='space-y-3'>
                <div className='flex justify-between items-center'>
                  <SettingGroupHeader title='Tax filing exclusion details' />
                  <Button
                    as='a'
                    variant='primary'
                    href={generateHref(values)}
                    onClick={() => isDisabled(true)}
                    disabled={
                      disabled ||
                      values.customerFeinUuid === '' ||
                      values.datetime === ''
                    }
                  >
                    Generate
                  </Button>
                </div>
                <DividingLine />
                <div>
                  This report will show tax codes with employees who were
                  dropped from filings because they were missing a SSN at the
                  time of filing. The report lists all employees with taxes in
                  the applicable tax code; employees who were dropped will have
                  a blank value in the "SSN at time of filing" column.
                </div>
                <div>
                  Time of filing is usually the most recent RTS run as listed in
                  the drop down. Previous RTS runs are listed as needed (for
                  instances of amendments, mistakes, etc.)
                </div>
                <form
                  id='create-form'
                  onSubmit={handleSubmit}
                  className='space-y-4 w-full md:w-3/4'
                >
                  <CustomerFeinsSelectField />
                  <div className='grid grid-cols-2 gap-2'>
                    <SelectField
                      id='year'
                      testId='year'
                      label='Year'
                      name='year'
                      options={filterYears(yearOptions)}
                    />
                    <SelectField
                      id='quarter'
                      testId='quarter'
                      label='Quarter'
                      name='quarter'
                      options={quarterOptions}
                    />
                  </div>
                  <RtsRunsSelectField />
                </form>
              </Panel>
              <Outlet />
            </PageBody>
          </Page>
        </LayoutProvider>
      )}
    </Formik>
  )
}

const CustomerFeinsSelectField = () => {
  const { data, isFetching } = useQuery({
    queryKey: ['feins'],
    queryFn: async () => {
      const response = await fetch('/payroll-operations/reports/feins')

      if (!response.ok) {
        throw new Error('Request failed')
      }

      return response.json()
    }
  })

  return (
    <SelectField
      label='Customer FEINs'
      name='customerFeinUuid'
      options={formatFeins(data, isFetching)}
    />
  )
}

const RtsRunsSelectField = () => {
  const { values, setFieldValue } = useFormikContext<ReportForm>()

  const {
    data: rtsRuns,
    status,
    isFetching
  } = useQuery({
    queryKey: ['rtsRuns', values.customerFeinUuid, values.quarter, values.year],
    queryFn: async () => {
      const response = await fetch(
        `/payroll-operations/reports/${values.customerFeinUuid}/rts-runs/?quarter=${values.quarter}&year=${values.year}`
      )

      if (!response.ok) {
        throw new Error('Request failed')
      }

      return response.json()
    },
    enabled: !!(values.customerFeinUuid && values.quarter && values.year),
    initialData: []
  })

  React.useEffect(() => {
    setFieldValue('datetime', '')
  }, [values.customerFeinUuid, values.year, values.quarter])

  return (
    <SelectField
      id='datetime'
      testId='datetime'
      label='RTS Runs'
      name='datetime'
      options={formatRtsRuns(rtsRuns, values, status, isFetching)}
    />
  )
}
