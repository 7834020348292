import * as React from 'react'
import { RouteObject } from 'react-router-dom'
import {
  DividingLine,
  Panel,
  SettingGroupHeader
} from '@toasttab/buffet-pui-config-templates'
import { Button } from '@toasttab/buffet-pui-buttons'

export const RTSRoute: RouteObject = {
  path: 'rts',
  element: <Rts />,
  children: []
}

function Rts() {
  return (
    <Panel>
      <div className='flex justify-between items-center'>
        <SettingGroupHeader title='Quarterly RTS file' />
        <Button variant='primary' onClick={() => console.log('rts file')}>
          Generate
        </Button>
      </div>
      <DividingLine />
      <div className='type-default'>
        We eventually need to move RTS here. This is a placeholder for now.
      </div>
    </Panel>
  )
}
