import * as React from 'react'
import { Link, Outlet, RouteObject } from 'react-router-dom'
import { AuditRoute } from './Audit'
import { RTSRoute } from './Rts'

import {
  Page,
  PageBody,
  PageHeader,
  HeadingGroup,
  Title
} from '@toasttab/buffet-pui-config-templates'
import {
  SecondaryNav,
  SecondaryNavItem,
  SecondaryNavSection
} from '@toasttab/buffet-pui-navigation'

export const EnterpriseReportsRoute: RouteObject = {
  path: 'payroll-ops/reports',
  element: <EnterpriseReports />,
  children: [AuditRoute, RTSRoute]
}

function EnterpriseReports() {
  return (
    <>
      <Page>
        <PageHeader>
          <HeadingGroup>
            <Title>Payroll operations internal reports</Title>
          </HeadingGroup>
        </PageHeader>
        <PageBody>
          <div className='flex stretch-items'>
            <div
              className='pr-6'
              style={{
                minWidth: '200px',
                maxWidth: '300px'
              }}
            >
              <SecondaryNav>
                <SecondaryNavSection>
                  <SecondaryNavItem
                    title='RTS file'
                    value='/rts'
                    as={Link}
                    to='/payroll-ops/reports/rts'
                  />
                  <SecondaryNavItem
                    title='Quarterly audit'
                    value='/audit'
                    as={Link}
                    to='/payroll-ops/reports/audit'
                    initialActive
                  />
                </SecondaryNavSection>
              </SecondaryNav>
            </div>
            <Outlet />
          </div>
        </PageBody>
      </Page>
    </>
  )
}
