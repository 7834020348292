export type VoidRequest = {
  id: number
  customer: string
  sourcePayPeriod: string
  targetPayPeriod: string
  employeeName: string
  employeeUuid: string
  status: string
  requestDate: string
  requestedUser: string
  netPayNotes: string
  payStubNumber: number
  notes: string
  requestType: string
  statusUser: string
  isScheduled: boolean
  sourcePayPeriodUuid: string | null
  customerUuid: string
}

export enum VoidRequestStatus {
  CLOSED = 'Closed',
  EXPIRED = 'Expired',
  OPEN = 'Open'
}

export type PagedList<T> = {
  totalCount: number
  results: T[]
}

export type IdentifiedObject = {
  uuid: string
  name: string
}

export enum VoidFilterType {
  CUSTOMER = 'Customer',
  PAYPERIOD = 'Pay Period',
  QUARTER = 'Quarter'
}
