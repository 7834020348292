import { banquetSingleSpaReact } from 'banquet-runtime-modules'
import { singleSpaCssLifecycles } from '@toasttab/banquet-single-spa-css'
import { domElementGetter } from '@toasttab/ec-layout'
import { AppBootstrap } from './app/App'
import './index.css'

const reactLifecycles = banquetSingleSpaReact({
  singleSpaCssLifecycles,
  cssScope: 'data-ec-payroll-ops',
  rootComponent: AppBootstrap,
  domElementGetter: domElementGetter.application,

  // sentry: {
  //   publicKey: '',
  //   projectId: '',
  //   releaseVersion: process.env.PKG_VERSION
  // }
  portalContainers: ['banquetPortalsContainer']
})

export const bootstrap = reactLifecycles.bootstrap
export const mount = reactLifecycles.mount
export const unmount = reactLifecycles.unmount
export const name = 'ec-payroll-operations-spa'
