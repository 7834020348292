import * as React from 'react'
import { Outlet, RouteObject } from 'react-router-dom'
import { EnterpriseReportsRoute } from './enterprise-reports/EnterpriseReports'
import { TaxFilingExclusionDetailsRoute } from './customer-reports/TaxFilingExclusionDetails'
import { RecalculateSutaWagesRoute } from './recalculate-suta-wages/RecalculateSutaWages'
import { VoidRequestsRoute } from './void-requests/VoidRequests'
import { VoidChecksRoute } from './void-checks/VoidChecks'

export const EntryRoute: RouteObject = {
  path: '/',
  element: <Outlet />,
  children: [
    EnterpriseReportsRoute,
    TaxFilingExclusionDetailsRoute,
    RecalculateSutaWagesRoute,
    VoidRequestsRoute,
    VoidChecksRoute
  ]
}
