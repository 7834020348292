import * as React from 'react'
import { useMutation } from '@tanstack/react-query'
import { Button } from '@toasttab/buffet-pui-buttons'
import { useSnackBar } from '@toasttab/buffet-pui-snackbars'
import { Modal, ModalHeader } from '@toasttab/buffet-pui-modal'

export function VoidRequestDeleteConfirmationModal({
  ids,
  selectedSingleId,
  isDeletionModalOpen,
  setDeletionModalOpen
}: {
  ids: number[]
  selectedSingleId: number | null
  setDeletionModalOpen: (reload: boolean) => void
  isDeletionModalOpen: boolean
}) {
  const { showSuccessSnackBar, showErrorSnackBar } = useSnackBar()

  const { mutate: deleteVoidRequests } = useMutation({
    mutationFn: async () => {
      const response = await fetch('/void-requests/deletion', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/com.toasttab.payroll.v1+json'
        },
        body: JSON.stringify(selectedSingleId ? [selectedSingleId] : ids)
      })
      if (!response.ok) {
        throw new Error('HTTP error: ' + response.status)
      }
    },
    onSuccess: () => {
      setDeletionModalOpen(true)
      showSuccessSnackBar('Void requests have been deleted')
    },
    onError: () => {
      setDeletionModalOpen(false)
      showErrorSnackBar('Error deleting void requests')
    }
  })

  return (
    <>
      <Modal isOpen={isDeletionModalOpen}>
        <ModalHeader>Delete Void Request</ModalHeader>
        <Modal.Body>
          <div className='space-y-3'>
            <div>
              Are you absolutely 100% positive you actually want to delete
              selected void requests?
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className='flex-grow sm:flex-none'
            variant='link'
            onClick={() => {
              setDeletionModalOpen(false)
            }}
          >
            Cancel
          </Button>
          <Button
            className='flex-grow sm:flex-none'
            onClick={() => {
              deleteVoidRequests()
            }}
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
