import React, { ReactNode } from 'react'
import { RouteObject } from 'react-router-dom'
import { Formik } from 'formik'

import {
  formatDate,
  getDefaultQuarter,
  getDefaultYear,
  queryClient
} from '@local/api/client'
import { Button } from '@toasttab/buffet-pui-buttons'
import {
  LayoutProvider,
  Panel,
  DividingLine,
  SettingGroupHeader
} from '@toasttab/buffet-pui-config-templates'
import { DatePickerField, SelectField } from '@toasttab/buffet-pui-forms'
import { SelectOption } from '@toasttab/buffet-pui-select'

export const AuditRoute: RouteObject = {
  path: 'audit',
  element: <Audit />,
  children: []
}

function Audit() {
  return (
    <LayoutProvider>
      <Form />
    </LayoutProvider>
  )
}

type Report = {
  label: string
  description?: ReactNode
  value: string
  needsQuarter: boolean
  needsYear: boolean
  needsDates?: boolean
  needsYtd?: boolean
}

const reportOptions: Report[] = [
  {
    label: 'California SUTA/SDI variance report',
    value: 'californiasutasdivariance',
    needsQuarter: true,
    needsYear: true
  },
  {
    label: 'Deleted demo employees',
    value: 'deletedDemoEmployees',
    needsQuarter: false,
    needsYear: true
  },
  {
    label: 'FICA medicare wage discrepancy',
    value: 'ficavariance',
    needsQuarter: false,
    needsYear: true
  },
  {
    label: 'Parallel customers',
    value: 'parallelcustomers',
    needsQuarter: true,
    needsYear: true,
    needsYtd: true
  },
  {
    label: 'P&P customers',
    value: 'priorcustomers',
    needsQuarter: false,
    needsYear: true,
    needsYtd: false
  },
  {
    label: 'Incorrect wage base',
    value: 'incorrectwagebase',
    needsQuarter: false,
    needsYear: true
  },
  {
    label: 'Duplicate CTS identifier',
    value: 'duplicatects',
    needsQuarter: false,
    needsYear: false
  },
  {
    label: 'Negative wages',
    value: 'negativewages',
    needsQuarter: true,
    needsYear: true
  },
  {
    label: 'WA L&I arrears',
    value: 'walniarrears',
    needsQuarter: true,
    needsYear: true
  },
  {
    label: 'Toast printed W2s',
    value: 'toastprintedw2s',
    needsQuarter: false,
    needsYear: true
  },
  {
    label: 'SSN campaign',
    value: 'ssncampaign',
    needsQuarter: false,
    needsYear: true
  },
  {
    label: 'Invalid SSNs',
    value: 'invalidssn',
    description: (
      <p>
        Generates a report that contains details about every employee with an
        invalid SSN and with a position tied to an active FEIN as of the start
        of the year. <br />
        <b>This report will take a while to run.</b>
      </p>
    ),
    needsQuarter: false,
    needsYear: true
  },
  {
    label: 'WA L&I setup',
    value: 'walnisetup',
    needsQuarter: false,
    needsYear: true
  },
  {
    label: 'Tax account audit',
    value: 'taxaccountaudit',
    needsQuarter: false,
    needsYear: true
  },
  {
    label: 'EIN campaign',
    value: 'eincampaign',
    needsQuarter: false,
    needsYear: false
  },
  {
    label: 'Recent SSN updates for dropped employees',
    value: 'recentssnupdates',
    needsQuarter: true,
    needsYear: true,
    needsDates: true
  },
  {
    label: 'Dropped employees',
    value: 'droppedemployees',
    needsQuarter: true,
    needsYear: true
  },
  {
    label: 'FICA medicare tax variance',
    value: 'ficamedtaxvariance',
    needsQuarter: false,
    needsYear: true
  }
  // add more enterprise reports here
]

export const sortedReportOptions = (reports: Report[]): Report[] => {
  reports.sort((a, b) => {
    const reportA = a.label.toUpperCase()
    const reportB = b.label.toUpperCase()

    if (reportA < reportB) {
      return -1
    }

    if (reportA > reportB) {
      return 1
    }

    return 0
  })

  return reports
}

export const findReport = (reportName: string): Report | undefined => {
  return reportOptions.find((report) => report.value === reportName)
}

export const generateHref = (values: RunAuditReportForm): string => {
  const report = findReport(values.report)
  if (!report) {
    return '' // should never actually happen
  }

  const path = `/reporting/internal/${values.report}`
  const params = new URLSearchParams()

  if (report.needsQuarter) {
    // if the report needs quarter as a field, it will also need year
    params.append('quarter', values.quarter.toString())
    params.append('year', values.year.toString())
  } else if (report.needsYear) {
    params.append('year', values.year.toString())
  }

  if (report.needsDates) {
    params.append(
      'startDate',
      values.startDate ? formatDate(values.startDate) : ''
    )
    params.append('endDate', values.endDate ? formatDate(values.endDate) : '')
  }

  return `${path}?${params.toString()}`
}

export const showQuarter = (reportName: string): boolean => {
  return !!findReport(reportName)?.needsQuarter
}

export const showYear = (reportName: string): boolean => {
  return !!findReport(reportName)?.needsYear
}

export const showDates = (reportName: string): boolean => {
  return !!findReport(reportName)?.needsDates
}

export const includeYtdOption = (
  quarterOptions: SelectOption[],
  reportName: string
): SelectOption[] => {
  const report = findReport(reportName)

  if (report?.needsYtd) {
    return [{ label: 'YTD', value: '0' }, ...quarterOptions]
  } else {
    return quarterOptions
  }
}

export const getDescription = (reportName: string): ReactNode | undefined => {
  return findReport(reportName)?.description
}

export const getHelperText = (
  fieldName: string,
  reportName: string
): string | undefined => {
  if (reportName === 'recentssnupdates') {
    switch (fieldName) {
      case 'startDate':
        return 'Start date should be the date you clicked the recalculate SUTA wages button'
      case 'endDate':
        return 'End date should be the date you variance posted in MasterTax (likely today)'
      default:
        return ''
    }
  }
}

type RunAuditReportForm = {
  report: string
  year: number
  quarter: number
  startDate?: Date
  endDate?: Date
}

function Form() {
  const yearOptions = queryClient.getQueryData(['years']) as SelectOption[]
  const quarterOptions = queryClient.getQueryData([
    'quarters'
  ]) as SelectOption[]

  return (
    <Formik<RunAuditReportForm>
      onSubmit={() => {}}
      initialValues={{
        report: reportOptions[0].value,
        year: getDefaultYear(new Date()),
        quarter: getDefaultQuarter(new Date()),
        startDate: undefined,
        endDate: undefined
      }}
    >
      {({ values, handleSubmit }) => (
        <Panel>
          <div className='flex justify-between items-center'>
            <SettingGroupHeader title='Quarterly audit reports' />
            <Button as='a' variant='primary' href={generateHref(values)}>
              Generate
            </Button>
          </div>
          <DividingLine />
          <form
            id='create-form'
            onSubmit={handleSubmit}
            className='space-y-6 w-full md:w-3/4'
          >
            <SelectField
              id='report'
              testId='report'
              label='Report'
              options={sortedReportOptions(reportOptions)}
              name='report'
            />
            {showYear(values.report) ? (
              <SelectField
                id='year'
                testId='year'
                label='Year'
                options={yearOptions}
                name='year'
              />
            ) : null}
            {showQuarter(values.report) ? (
              <SelectField
                id='quarter'
                testId='quarter'
                label='Quarter'
                options={includeYtdOption(quarterOptions, values.report)}
                name='quarter'
              />
            ) : null}
            {showDates(values.report) ? (
              <div className='flex items-stretch space-x-4'>
                <DatePickerField
                  id='startDate'
                  testId='startDate'
                  label='Start Date'
                  name='startDate'
                  helperText={getHelperText('startDate', values.report)}
                />
                <DatePickerField
                  id='endDate'
                  testId='endDate'
                  label='End Date'
                  name='endDate'
                  helperText={getHelperText('endDate', values.report)}
                />
              </div>
            ) : null}
            {getDescription(values.report) !== undefined ? (
              <p className='type-default mb-4'>
                {getDescription(values.report)}
              </p>
            ) : null}
          </form>
        </Panel>
      )}
    </Formik>
  )
}
